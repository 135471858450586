<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Blog Category </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \
                  Blog Category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createBlogCategory"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Blog Category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      v-on:keyup.enter="searchBlogCategory"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                      :items="parents"
                      v-model="search.parent_id"
                      v-on:keyup.enter="searchBlogCategory"
                      @input="search.parent_id = $event !== null ? $event : ''"
                      label="Parent"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchBlogCategory"
                      @input="search.is_active = $event !== null ? $event : ''"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn @click="searchBlogCategory()" class="mt-1 btn btn-primary" style="color: #fff"
                         :loading="loading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Name</strong></th>
                  <th><strong>Position</strong></th>
                  <th><strong>Parent</strong></th>
                  <th><strong>Is Parent</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="blogCategories" @change="sort" @start="drag=true" tag="tbody"
                             @end="drag=false">
                    <tr v-for="(blogCategory, index) in blogCategories" :key="index">
                      <td>
                        <a @click="editBlogCategory(blogCategory)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          <i class="fa fa-sort"></i> {{ blogCategory.name }}
                        </a>
                      </td>

                      <td>
                        {{ blogCategory.position }}
                      </td>


                      <td>
                        {{ blogCategory.parent_name ? blogCategory.parent_name : '--' }}
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class=" !blogCategory.is_parent? 'badge-danger': 'badge-success' "
                        >{{ blogCategory.is_parent ? 'YES' : 'NO' }}</span>
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': blogCategory.is_active, 'badge-danger': !blogCategory.is_active }"
                        >{{ blogCategory.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="editBlogCategory(blogCategory)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteBlogCategory(blogCategory.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="blogCategories.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="7">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>
              <b-pagination
                  v-if="blogCategories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllBlogCategories"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->

            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllBlogCategories"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import BlogCategoryService from "@/services/cms/blog/category/BlogCategoryService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from "vuedraggable";

const blogCategory = new BlogCategoryService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  name: "Index",
  data() {
    return {
      loading: false,
      isSorting: false,
      total: null,
      perPage: null,
      page: null,
      blogCategories: [],
      parents: [],
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search: {
        name: '',
        parent_id: '',
        is_active: '',
      }
    }
  },
  mounted() {
    this.getAllBlogCategories();
    this.getAllParent();
  },
  methods: {
    createBlogCategory() {
      this.$refs['create-and-update'].createBlogCategory();
    },
    editBlogCategory(blogCategory) {
      this.$refs['create-and-update'].editBlogCategory(blogCategory);
    },

    getAllBlogCategories() {
      this.loading = true;
      blogCategory
          .paginate(this.search, this.page)
          .then(response => {
            this.blogCategories = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllParent() {
      blogCategory
          .getParent()
          .then(response => {
            this.parents = response.data.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },

    sort() {
      this.isSorting = true;
      blogCategory
          .sort(this.blogCategories)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Blog Category Sorted !!');
            this.getAllBlogCategories();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchBlogCategory() {
      this.getAllBlogCategories();
    },
    deleteBlogCategory(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            blogCategory
                .delete(id)
                .then((response) => {
                  this.getAllBlogCategories();
                  this.$snotify.success("Blog Category Deleted Successfully");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },

}
</script>